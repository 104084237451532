body {
  /* Color scheme: https://rosepinetheme.com/ */
  --base: #faf4ed;
  --surface: #fffaf3;
  --overlay: #f2e9e1;
  --muted: #9893a5;
  --subtle: #797593;
  --text: #575279;
  --love: #b4637a;
  --gold: #ea9d34;
  --gold-light: #f3c88c;
  --rose: #d7827e;
  --pine: #286983;
  --foam: #56949f;
  --iris: #907aa9;
  --highlight-low: #f4ede8;
  --highlight-med: #dfdad9;
  --highlight-high: #cecacd;
  margin: 0;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  color: var(--text);
  background-color: var(--base);
}

a, span {
  color: var(--text);
  font-weight: 500;
  text-decoration: none;
}

a:hover, span:hover {
  color: var(--subtle);
}

p, li {
  line-height: 1.75em;
}

ul {
  margin-top: -1em;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--pine);
  font-size: 1.75em;
  font-weight: 700;
}

h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--text);
  font-size: 1.2em;
}

h3 {
  color: var(--text);
  margin-top: 0;
}

h4 {
  color: var(--text);
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1em;
  font-style: italic;
  font-weight: normal;
}

img {
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

#experience img {
  margin-bottom: 0;
}

hr {
  margin: 1.5em 0;
  border: none;
  height: 4px;
  background: linear-gradient(160deg, var(--rose), var(--love), var(--rose));
}

/* ===================================================================== */
/* ------------------------------- header ------------------------------ */
/* ===================================================================== */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.icons img, svg {
  /* fill: var(--text); */
  fill: url(#pattern); 
  height: 30px;
}

#pattern {
  --color-stop: #f12c06;
  --color-bot: #faed34;
}

.icons svg:hover {
  fill: var(--subtle);
}

/* ===================================================================== */
/* ------------------------------ sidebar ------------------------------ */
/* ===================================================================== */
.sidebar {
  background-color: var(--base);
  position: absolute;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sidebar::after {
  position: absolute;
  top: 0px; bottom: 0px;
  left: -4px; right: 0px;
  background: linear-gradient(180deg, var(--foam), var(--pine));
  content: '';
  z-index: -1;
}

.sidebar a, span {
  margin: 10px 15px;
}

/* ===================================================================== */
/* ------------------------------- title ------------------------------- */
/* ===================================================================== */
.title {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-size: 3.5em;
  line-height: 1em;
  font-weight: 800;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  background-image: linear-gradient(25deg,var(--love),var(--rose) 50%);
  background-color: rgba(0, 0, 0, 0);
  background-size: 100%;
  position: relative;
  display: inline-block;
}

/* ===================================================================== */
/* ------------------------------ content ------------------------------ */
/* ===================================================================== */
.content-container {
  position: absolute;
  display: inline;
  background-color: var(--surface);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 1em 1em 0 1em;
}

.content {
  width: 100%;
  position: relative;
  text-align: left;
  display: inline-block;
}

/* ===================================================================== */
/* -------------------- Extra small devices (mobile) ------------------- */
/* ===================================================================== */
 @media only screen and (max-width: 600px) {
  body {
    font-size: 1em;
  }
  .header {
    width: 90vw;
    margin: 1em 5vw;
    position: relative;
  }
  .icons {
    width: 90px;
    display: grid;
    grid-template-columns: repeat(2, 35px);
    gap: 20px;
    grid-auto-rows: minmax(35px, 35px);
  }
  .icons img, svg {
    fill: var(--text);
    height: 35px;
    margin: 0;
  }
  .sidebar {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 0;
    grid-auto-rows: minmax(2.25em, 2.25em);
    position: relative;
  }
  .sidebar::after {
    display: none;
  }
  .content-container {
    left: 0;
    margin: 1em auto;
    width: 85vw;
    position: relative;
    display: block;
  }
 }

/* ===================================================================== */
/* ------------------ Small devices (mobile, tablets) ------------------ */
/* ===================================================================== */
 @media only screen and (min-width: 600px) {
  .header {
    width: 90vw;
    margin: 1em 5vw;
    position: relative;
  }
  .icons {
    width: 90px;
    display: grid;
    grid-template-columns: repeat(2, 35px);
    gap: 20px;
    grid-auto-rows: minmax(35px, 35px);
  }
  .icons img, svg {
    fill: var(--text);
    height: 35px;
    margin: 0;
  }
  .sidebar {
    left: 5vw;
  }
  .content-container {
    right: 5vw;
    width: 60vw;
  }
 }
 
/* ===================================================================== */
/* --------------------- Medium devices (tablets) ---------------------- */
/* ===================================================================== */
 @media only screen and (min-width: 768px) {
  .header {
    width: 80vw;
    margin: 2em 10vw;
  }
  .sidebar {
    left: 10vw;
  }
  .content-container {
    right: 10vw;
    width: 55vw;
  }
 }
 
/* ===================================================================== */
/* ----------------- Large devices (laptops, desktops) ----------------- */
/* ===================================================================== */
 @media only screen and (min-width: 1400px) {
  .header {
    width: 70vw;
    margin: 2em 15vw;
  }
  .sidebar {
    left: 15vw;
  }
  .content-container {
    right: 15vw;
    width: 55vw;
  }
 }